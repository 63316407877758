<template>
  <div class="main-header">
    <div class="main-header__left">
      <div class="user-name">
        <h4>Draft in Muhammad Hassan</h4>
        <p>
          Current: Aug. 13 2021, 06:11p.m. | UTC Created: DEC 13 2020, 10:16am
        </p>
      </div>
      <div class="calendar" @click="showCalendar = !showCalendar">
        <img class="icon icon-calendar" src="../../assets/icons/calendar.svg" />
        <p>No Due Date</p>
        <!-- <div v-if="showCalendar" style="width: 300px; 
        border: 1px solid $color-dark-gray-4; 
        border-radius: 4px; 
        position: absolute; 
        top: 2rem; 
        left: 0; 
        z-index: 1;
        margin-top: 3rem;
        background-color: white;">
    <a-calendar v-model:value="value" :fullscreen="false" @panelChange="onPanelChange" @select="onSelect">>
      <template #headerRender="{ value, type, onChange, onTypeChange }">
        <div style="padding: 10px">
          <div style="margin-bottom: 10px">Custom header</div>
          <a-row type="flex" justify="space-between">
            <a-col>
              <a-radio-group size="small" :value="type" @change="e => onTypeChange(e.target.value)">
                <a-radio-button value="month">Month</a-radio-button>
                <a-radio-button value="year">Year</a-radio-button>
              </a-radio-group>
            </a-col>
            <a-col>
              <a-select
                size="small"
                :dropdown-match-select-width="false"
                class="my-year-select"
                :value="String(value.year())"
                @change="
                  newYear => {
                    onChange(value.clone().year(newYear));
                  }
                "
              >
                <a-select-option
                  v-for="val in getYears(value)"
                  :key="String(val)"
                  class="year-item"
                >
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                size="small"
                :dropdown-match-select-width="false"
                :value="String(value.month())"
                @change="
                  selectedMonth => {
                    onChange(value.clone().month(parseInt(selectedMonth, 10)));
                  }
                "
              >
                <a-select-option
                  v-for="(val, index) in getMonths(value)"
                  :key="String(index)"
                  class="month-item"
                >
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>
      </template>
    </a-calendar>
    </div> -->
      </div>
    </div>
    <div class="main-header__right">
      <div class="collaborator">
        <h4>Collaborators</h4>
        <p v-if="collaborators.length == 0">No collaborators right now</p>
        <div v-else class="collaborator__images-sec">
          <div v-for="(collaborator, i) in collaborators.slice(0,3)" :key="collaborator.id" class="collaborator__image" :class="'image'+(i+1)">
            <img
              :src="
                require(`../../assets/images/Profile-pic@2x.png`)
              "
              alt=""
              class="image"
            />
          </div>
        </div>
      </div>

      <div class="profile">
        <div v-if="manage" class="manage">
          <a @click.prevent="openManageDrawer" class="manage__link" 
            >Manage Team Roles</a
          >
        </div>
        <div class="wrapper" :class="{'disabled': (selectedArticle===null)}">
          <div class="icon" @click="showModal()">
            <i class="icon-account_add" ></i>
          </div>
        </div>
        
        <div class="icon-two">
          <i class="icon-download_arrow"></i>
        </div>
        <a-button class="button btn-primary" :loading="publishingArticle" @click="publishArticle">
          Publish Article
        </a-button>
      </div>
    </div>
  </div>
  <a-drawer
    placement="right"
    :visible="ShowmanageRoleDrawer"
    @close="ShowmanageRoleDrawer = false"
    width="45.5rem"
    wrapClassName="collaboration-drawer"
  >
    <div>
      <h2>Manage Team Roles</h2>
      <div v-for="collaborator in collaborators" :key="collaborator.id" class="user-team">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="Team Member" />
        </div>
        <div class="user-team-datails">
          <h3>{{collaborator.name}}</h3>
          <p>New York, United States</p>
        </div>
        <div class="user-roles">
          <h2>Role</h2>
          <div class="dropdown">
            <a-select
              ref="select"
              v-model:value="selectedRoles[collaborator.user]"
              :options="collaboratorRoles"
              style="width: max-content"
              @change="updateRole(collaborator.user)"
            >
            </a-select>
            <!-- <p>Editor</p> -->
            <!-- <i class="icon icon-forward"></i> -->
          </div>
        </div>
      </div>
      <!-- <div class="user-team">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="Team Member" />
        </div>
        <div class="user-team-datails">
          <h3>Collier Rhea</h3>
          <p>New York, United States</p>
        </div>
        <div class="user-roles">
          <h2>Role</h2>
          <div class="dropdown">
            <p>Editor</p>
            <i class="icon icon-forward"></i>
          </div>
        </div>
      </div>
      <div class="user-team">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="Team Member" />
        </div>
        <div class="user-team-datails">
          <h3>Collier Rhea</h3>
          <p>New York, United States</p>
        </div>
        <div class="user-roles">
          <h2>Role</h2>
          <div class="dropdown">
            <p>Editor</p>
            <i class="icon icon-forward"></i>
          </div>
        </div>
      </div>
      <div class="user-team">
        <div class="user-img">
          <img src="../../assets/images/profile-pic.webp" alt="Team Member" />
        </div>
        <div class="user-team-datails">
          <h3>Collier Rhea</h3>
          <p>New York, United States</p>
        </div>
        <div class="user-roles">
          <h2>Role</h2>
          <div class="dropdown">
            <p>Editor</p>
            <i class="icon icon-forward"></i>
          </div>
        </div>
      </div> -->
    </div>
  </a-drawer>
  <request-collaborate
    :visible="visibleCollaborateModal"
    title="Send Request for Collaboration"
    @cancel="visibleCollaborateModal = false"
    :footer="null"
    wrapClassName="request-collaboration-modal"
  ></request-collaborate>
</template>

<script>
import RequestCollaborate from "./RequestCollaborateModal.vue";
export default {
  components: {
    RequestCollaborate,
  },
  props: {
    manage: Boolean,
  },
  data() {
    return {
      showCalendar: false,
      visibleCollaborateModal: false,
      ShowmanageRoleDrawer: false,
      showMessageModal: false,
      message: {},
      publishingArticle: false,
      err: {},
      collaboratorRoles: [
        {
          value: 'editor',
          label: 'Editor',
        },
        {
          value: 'admin',
          label: 'Admin',
        },
        {
          value: 'owner',
          label: 'Owner',
        },
      ],
    };
  },
  computed:{
    selectedArticle(){
      return this.$store.getters['editor/getPublishedArticle'];
    },
    collaborators(){
      return this.$store.getters['editor/getAllCollaborators'];
    },
    selectedRoles(){
      let roles = {}
       this.collaborators.map((val)=>{
        roles[val.user] = val.role;
      })
      return roles;
    }
  },
  methods: {
    // getYears(value){
    //   console.log(value);
    //   const year = value.year();
    //   const years = [];
    //   for (let i = year - 10; i < year + 10; i += 1) {
    //     years.push(i);
    //   }
    //   return years;
    // },
    // getMonths(value){
    //   console.log(value);
    //   const current = value.clone();
    //   const localeData = value.localeData();
    //   const months = [];
    //   for (let i = 0; i < 12; i++) {
    //     current.month(i);
    //     months.push(localeData.monthsShort(current));
    //   }
    //   return months;
    // },
    // onPanelChange(value, mode){
    //   console.log(value, mode);
    // },
    // onSelect(value){
    //   console.log(value);
    // },
    showModal() {
      this.visibleCollaborateModal = true;
    },
    openManageDrawer() {
      this.ShowmanageRoleDrawer = true;
    },
    async publishArticle() {
      this.err = {}
      let title = this.$store.getters["editor/getArticleTitle"];
      let content = this.$store.getters["editor/getArticleBody"].articleBodyTEXT;
      if(title === "" || content === ""){
        if(title === ""){
           this.err.title = "Title of article is required";
        }
        if(content === ""){
          this.err.content = "Article content cannot be empty"
        }
        this.emitter.emit('publishArticle', this.err);
        return;
      }
      const requestObj = {
        "title": this.$store.getters["editor/getArticleTitle"],
        "body_markdown": this.$store.getters["editor/getArticleBody"].articleBodyHTML,
        "body_text": this.$store.getters["editor/getArticleBody"].articleBodyTEXT,
        "is_draft": false,
        "article_id": ""
      }
      this.publishingArticle = true;
      try {
      const response = await this.$store.dispatch('editor/publishArticle', requestObj);
      if (response.status === 201) {
        this.message = {
          title: "Article Published",
          desc: "Your article is being published and will be available to read within next 24 hours. Thanks for writing with Glocal.",
          type: "success"
        }
        this.showMessageModal = true;
        // this.$store.dispatch('editor/setTitle', "");
        // this.$store.dispatch('editor/setArticleBody', {articleBodyTEXT: "", articleBodyHTML: ""});
        this.emitter.emit('publishArticle', {});
      }
      }catch(err){
        this.message = {
          title: "Something went wrong",
          desc: "An error has occured while publishing article. Please try again later. Sorry for the inconvience.",
          type: "failure"
        }
        this.showMessageModal = true;
      }
      this.publishingArticle = false;
    },
    updateRole(user){
      console.log('update role to ' +this.selectedRoles[user])
    }
  },
};
</script>
<style lang="scss">
@include reset-css;
.ant-select-dropdown{
    z-index: 20000000 !important;
  }
.collaboration-drawer {
  .ant-drawer-body {
    padding: 4rem;
    padding-right: 9rem;
  }
  .ant-drawer-close {
    top: 3rem !important;
    right: 3rem !important;
    font-size: 2rem;
    color: $color-black;
  }

  h2 {
    font-size: 2.5rem;
    font-family: $font-secondary-bold;
    font-weight: 800;
    margin-bottom: 8rem;
  }
  .user-team {
    display: flex;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 0.24rem solid #7070700a;
    .user-img {
      width: 8.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .user-team-datails {
      margin: 0 1.5rem;
      width: 100%;
      h3 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-primary;
        margin-bottom: 0;
      }
    }
    .user-roles {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 10rem;

      h2 {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        padding-top: 0.3rem;
      }
      .dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-select-focused{
          border: none;
          outline: none;
          box-shadow: none;
          .ant-select-selector{
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
        .ant-select-selector{
          border: none;
          font-weight: bold;
          padding-left: 0;
          outline: none;
          font-size: 1.4rem;
        }
        p {
          font-size: 1.2rem;
          font-family: $font-primary;
          margin-bottom: 0;
        }
        .icon {
          font-size: 1.2rem;
          transform: rotate(90deg);
        }
      }
    }
  }
}
.main-header {
  padding: 1.6rem 0;
  border-bottom: 0.24rem solid #7070701f;
  border-top: 0.24rem solid #7070701f;
  margin-top: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond(laptop-small) {
    flex-direction: column;
  }
  &__left {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .user-name {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        margin-bottom: 0.2rem;
      }
      p {
        font-size: 1.6rem;
        opacity: 0.5;
        font-family: $font-secondary;
      }
    }
    .calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0.22rem solid #7070706c;
      border-right: 0.22rem solid #7070706c;
      padding: 0.8rem 0rem;
      margin: 0 2rem 0 0.8rem;
      position: relative;
      cursor: pointer;
      @include respond(laptop-small) {
        margin-right: 0;
      }
      .icon {
        font-size: 2.4rem;
        opacity: 0.5;
        margin-right: 1.3rem;
        margin-left: 1.5rem;
      }
      p {
        font-size: 1.4rem;
        font-family: $font-secondary;
        opacity: 0.5;
        width: 80px;
        margin-right: 1.3rem;
        margin-bottom: 0;
        @include respond(laptop-small) {
          width: 100%;
        }
      }
    }
  }
  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include respond(laptop-small) {
      justify-content: flex-start;
      margin-top: 2rem;
    }
    .collaborator {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 1.2rem;
        font-family: $font-secondary-bold;
        margin-bottom: 0.6rem;
      }
      p {
        font-size: 1.2rem;
        font-family: $font-secondary;
        opacity: 0.5;
      }
      &__images-sec {
        display: flex;
      }
      &__image {
        width: 4rem;
        height: 4rem;
        .image {
          width: 100%;
          border-radius: 50%;
        }
      }
      .image2 {
        transform: translateX(-50%);
      }
      .image3 {
        transform: translateX(-100%);
      }
    }

    .profile {
      display: flex;
      align-items: center;
      .manage {
        margin-right: 2rem;
        a {
          color: black;
          text-decoration: underline;
          font-weight: bold;
          font-size: 1.6rem;
        }
      }
      .disabled{
        cursor: not-allowed;
        .icon{
          pointer-events: none;
        }
      }
      .icon {
        border: 2px solid $color-black;
        font-size: 2.5rem;
        padding: 0.42rem 1.1rem;
        cursor: pointer;
      }
      .icon-two {
        font-size: 2rem;
        padding: 1.6rem;
        color: $color-white;
        background-color: $color-black;
        margin-left: 0.9rem;
        cursor: pointer;
      }
      .button {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        font-weight: 600;
        margin-left: 0.9rem;
        padding: 1.5rem 0.5rem;
        height: 5.2rem;
        cursor: pointer;
        width: 17rem;
        border: none;
        @include respond(phone-x-small) {
          width: 15rem;
        }
      }
      .down-icon {
        font-size: 1.4rem;
        transform: rotate(90deg);
        padding: 1.4rem;
      }
    }
  }
}

.request-collaboration-modal {
  .ant-modal {
    width: 50rem !important;
    top: 85px;
  }
  .ant-modal-header {
    padding: 0;
    border-bottom: none;
    margin-bottom: 2rem;
    background-color: transparent;
    .ant-modal-title {
      font-size: 2.5rem;
      font-family: $font-secondary-bold;
    }
  }
  .ant-modal-content {
    padding: 4rem 3rem;
    background-color: #f6f8ff;
    border-radius: 0;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 5rem;
  .tick-circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #169a07;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    .icon {
      color: white;
      font-size: 3rem;
    }
  }
  .publish-heading {
    font-family: $font-secondary-bold;
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }
  .publish-text {
    font-family: $font-primary;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}
</style>
